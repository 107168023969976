.image {
  &:hover {
    opacity: 0.9;
  }
}

.pageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  width: 100%;
}
