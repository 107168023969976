.cardActionContainer {
  display: flex;
  flex-direction: column;
}

.cardActions {
  justify-content: center;
}

.cards {
  &:hover {
    color: rgba(0, 83, 164, 1);
    cursor: pointer;
    opacity: 0.85;
  }
}

.cardTitle {
  color: inherit;
  display: flex;
  flex-direction: row;
}

.closeButton {
  color: #00000099;
  &:hover {
    color: black;
  }
}

.description {
  text-align: center;
}

.menuIcon {
  color: #0053a4;
  cursor: pointer;
  margin: -13px -12px 0 auto;
  &:hover {
    color: #1e8fff;
  }
}

.moreInfo {
  display: flex;
  flex-direction: column;
}

.moreInfoContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.moreInfoHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  width: 100%;
}

.stickyHeader {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: sticky;
  top: 0;
}

.tabs {
  align-self: flex-start;
  margin: 0 7px 10px 7px;
}
